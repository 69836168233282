/*
 *  Document   : themes.less
 *  Author     : pixelcave
 *  Description: The themes stylesheet of the template. The classes can be used to
 *  alter the text, the background or the border color of an element individually
 *
 */

/*
=================================================================
THEME DEFAULT
=================================================================
*/

.themed-color-default {
  color: @color_highlight !important;
}

.themed-border-default {
  border-color: @color_highlight !important;
}

.themed-background-default {
  background-color: @color_highlight !important;
}

.themed-color-dark-default {
  color: @color_dark !important;
}

.themed-border-dark-default {
  border-color: @color_dark !important;
}

.themed-background-dark-default {
  background-color: @color_dark !important;
}

/*
=================================================================
THEME NIGHT
=================================================================
*/

.themed-color-night {
  color: @color_highlight_night !important;
}

.themed-border-night {
  border-color: @color_highlight_night !important;
}

.themed-background-night {
  background-color: @color_highlight_night !important;
}

.themed-color-dark-night {
  color: @color_dark_night !important;
}

.themed-border-dark-night {
  border-color: @color_dark_night !important;
}

.themed-background-dark-night {
  background-color: @color_dark_night !important;
}

/*
=================================================================
THEME AMETHYST
=================================================================
*/

.themed-color-amethyst {
  color: @color_highlight_amethyst !important;
}

.themed-border-amethyst {
  border-color: @color_highlight_amethyst !important;
}

.themed-background-amethyst {
  background-color: @color_highlight_amethyst !important;
}

.themed-color-dark-amethyst {
  color: @color_dark_amethyst !important;
}

.themed-border-dark-amethyst {
  border-color: @color_dark_amethyst !important;
}

.themed-background-dark-amethyst {
  background-color: @color_dark_amethyst !important;
}

/*
=================================================================
THEME MODERN
=================================================================
*/

.themed-color-modern {
  color: @color_highlight_modern !important;
}

.themed-border-modern {
  border-color: @color_highlight_modern !important;
}

.themed-background-modern {
  background-color: @color_highlight_modern !important;
}

.themed-color-dark-modern {
  color: @color_dark_modern !important;
}

.themed-border-dark-modern {
  border-color: @color_dark_modern !important;
}

.themed-background-dark-modern {
  background-color: @color_dark_modern !important;
}

/*
=================================================================
THEME AUTUMN
=================================================================
*/

.themed-color-autumn {
  color: @color_highlight_autumn !important;
}

.themed-border-autumn {
  border-color: @color_highlight_autumn !important;
}

.themed-background-autumn {
  background-color: @color_highlight_autumn !important;
}

.themed-color-dark-autumn {
  color: @color_dark_autumn !important;
}

.themed-border-dark-autumn {
  border-color: @color_dark_autumn !important;
}

.themed-background-dark-autumn {
  background-color: @color_dark_autumn !important;
}

/*
=================================================================
THEME FLATIE
=================================================================
*/

.themed-color-flatie {
  color: @color_highlight_flatie !important;
}

.themed-border-flatie {
  border-color: @color_highlight_flatie !important;
}

.themed-background-flatie {
  background-color: @color_highlight_flatie !important;
}

.themed-color-dark-flatie {
  color: @color_dark_flatie !important;
}

.themed-border-dark-flatie {
  border-color: @color_dark_flatie !important;
}

.themed-background-dark-flatie {
  background-color: @color_dark_flatie !important;
}

/*
=================================================================
THEME SPRING
=================================================================
*/

.themed-color-spring {
  color: @color_highlight_spring !important;
}

.themed-border-spring {
  border-color: @color_highlight_spring !important;
}

.themed-background-spring {
  background-color: @color_highlight_spring !important;
}

.themed-color-dark-spring {
  color: @color_dark_spring !important;
}

.themed-border-dark-spring {
  border-color: @color_dark_spring !important;
}

.themed-background-dark-spring {
  background-color: @color_dark_spring !important;
}

/*
=================================================================
THEME FANCY
=================================================================
*/

.themed-color-fancy {
  color: @color_highlight_fancy !important;
}

.themed-border-fancy {
  border-color: @color_highlight_fancy !important;
}

.themed-background-fancy {
  background-color: @color_highlight_fancy !important;
}

.themed-color-dark-fancy {
  color: @color_dark_fancy !important;
}

.themed-border-dark-fancy {
  border-color: @color_dark_fancy !important;
}

.themed-background-dark-fancy {
  background-color: @color_dark_fancy !important;
}

/*
=================================================================
THEME FIRE
=================================================================
*/

.themed-color-fire {
  color: @color_highlight_fire !important;
}

.themed-border-fire {
  border-color: @color_highlight_fire !important;
}

.themed-background-fire {
  background-color: @color_highlight_fire !important;
}

.themed-color-dark-fire {
  color: @color_dark_fire !important;
}

.themed-border-dark-fire {
  border-color: @color_dark_fire !important;
}

.themed-background-dark-fire {
  background-color: @color_dark_fire !important;
}

/*
=================================================================
THEME CORAL
=================================================================
*/

.themed-color-coral {
  color: @color_highlight_coral !important;
}

.themed-border-coral {
  border-color: @color_highlight_coral !important;
}

.themed-background-coral {
  background-color: @color_highlight_coral !important;
}

.themed-color-dark-coral {
  color: @color_dark_coral !important;
}

.themed-border-dark-coral {
  border-color: @color_dark_coral !important;
}

.themed-background-dark-coral {
  background-color: @color_dark_coral !important;
}

/*
=================================================================
THEME LAKE
=================================================================
*/

.themed-color-lake {
  color: @color_highlight_lake !important;
}

.themed-border-lake {
  border-color: @color_highlight_lake !important;
}

.themed-background-lake {
  background-color: @color_highlight_lake !important;
}

.themed-color-dark-lake {
  color: @color_dark_lake !important;
}

.themed-border-dark-lake {
  border-color: @color_dark_lake !important;
}

.themed-background-dark-lake {
  background-color: @color_dark_lake !important;
}

/*
=================================================================
THEME FOREST
=================================================================
*/

.themed-color-forest {
  color: @color_highlight_forest !important;
}

.themed-border-forest {
  border-color: @color_highlight_forest !important;
}

.themed-background-forest {
  background-color: @color_highlight_forest !important;
}

.themed-color-dark-forest {
  color: @color_dark_forest !important;
}

.themed-border-dark-forest {
  border-color: @color_dark_forest !important;
}

.themed-background-dark-forest {
  background-color: @color_dark_forest !important;
}

/*
=================================================================
THEME WATERLILY
=================================================================
*/

.themed-color-waterlily {
  color: @color_highlight_waterlily !important;
}

.themed-border-waterlily {
  border-color: @color_highlight_waterlily !important;
}

.themed-background-waterlily {
  background-color: @color_highlight_waterlily !important;
}

.themed-color-dark-waterlily {
  color: @color_dark_waterlily !important;
}

.themed-border-dark-waterlily {
  border-color: @color_dark_waterlily !important;
}

.themed-background-dark-waterlily {
  background-color: @color_dark_waterlily !important;
}

/*
=================================================================
THEME EMERALD
=================================================================
*/

.themed-color-emerald {
  color: @color_highlight_emerald !important;
}

.themed-border-emerald {
  border-color: @color_highlight_emerald !important;
}

.themed-background-emerald {
  background-color: @color_highlight_emerald !important;
}

.themed-color-dark-emerald {
  color: @color_dark_emerald !important;
}

.themed-border-dark-emerald {
  border-color: @color_dark_emerald !important;
}

.themed-background-dark-emerald {
  background-color: @color_dark_emerald !important;
}


/*
=================================================================
THEME BLACKBERRY
=================================================================
*/

.themed-color-blackberry {
  color: @color_highlight_blackberry !important;
}

.themed-border-blackberry {
  border-color: @color_highlight_blackberry !important;
}

.themed-background-blackberry {
  background-color: @color_highlight_blackberry !important;
}

.themed-color-dark-blackberry {
  color: @color_dark_blackberry !important;
}

.themed-border-dark-blackberry {
  border-color: @color_dark_blackberry !important;
}

.themed-background-dark-blackberry {
  background-color: @color_dark_blackberry !important;
}
