/*
 *  Document: nprogress.less
 *
 *  NProgress (c) 2013, Rico Sta. Cruz
 *
 *  http://ricostacruz.com/nprogress
 *
 */

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  -webkit-pointer-events: none;
}

#nprogress .bar {
  background-color: @color_highlight;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px @color_highlight, 0 0 5px @color_highlight;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1050;
  top: 15px;
  left: 50%;
  margin-left: -10px;
}

#nprogress .spinner-icon {
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
  border-top-color: @color_highlight;
  border-left-color: @color_highlight;
  border-radius: 10px;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  -moz-animation: nprogress-spinner 400ms linear infinite;
  -ms-animation: nprogress-spinner 400ms linear infinite;
  -o-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes nprogress-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes nprogress-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes nprogress-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 8 Απρ 2014, 11:34:24 πμ
    Author     : John
*/
