/*
 *  Document   : pages.less
 *  Author     : pixelcave
 *
 *  PAGES
 *  Styles needed only on specific pages
 *
 */

/* Login */
#login-background {
  width: 100%;
  height: 224px;
  overflow: hidden;
  position: relative;
}

#login-background > img {
  position: absolute;
  width: 2560px;
  height: 400px;
  left: 50%;
  margin-left: -1280px;
}

#login-alt-container {
  display: none;
}

#login-container,
#login-alt-container {
  position: absolute;
  width: 300px;
  top: 10px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
}

#login-container .login-title {
  padding: 20px 10px;
  background: #444444;
  background: url('@{assets_folder}/template/ie8_opacity_dark_60.png') repeat;
  background: rgba(0, 0, 0, 0.6);
}

#login-container .login-title h1,
#login-alt-container h1 {
  font-size: 26px;
  color: #ffffff;
}

#login-container .login-title h1 small,
#login-alt-container h1 small {
  font-size: 16px;
  color: #dddddd;
}

#login-container > .block {
  border: none;
}

#login-container .register-terms {
  line-height: 30px;
  margin-right: 10px;
  float: left;
}

/* Full Background Image */
img.full-bg {
  min-height: 100%;
  min-width: 1280px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1280px) {
  img.full-bg {
    left: 50%;
    margin-left: -640px;
  }
}

/* Full Page Container */
.full-page-container {
  position: absolute;
  width: 300px;
  padding: 10px;
  top: 20px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
  background-color: #333333;
  background-color: rgba(0, 0, 0, .5);
}

.full-page-section {
  padding: 25px 0;
  margin: 25px -10px;
  background-color: #000000;
  background-color: rgba(0, 0, 0, .15);
}

/* Countdown */
.countdown-con {
  color: #ffffff;
  text-align: center;
}

.countdown-con .countdown-num {
  font-size: 56px;
  font-weight: bold;
}

.countdown-con .countdown-info {
  font-size: 16px;
  color: rgba(255, 255, 255, .6);
}

/* Calendar */
.calendar-events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calendar-events li {
  color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #555555;
  opacity: 0.85;
}

.calendar-events li:hover {
  cursor: move;
  opacity: 1;
}

/* Gallery */
.gallery img, .gallery a img, .gallery-image img, a[data-toggle="lightbox-image"] img {
  max-width: 100%;
}

a[data-toggle="lightbox-image"],
a.gallery-link {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.gallery a:hover img,
.gallery-image:hover img,
a[data-toggle="lightbox-image"]:hover img {
  opacity: 0.75;
}

.gallery-image {
  position: relative;
}

.gallery-image-options {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  padding: 10px;
}

.gallery-image:hover .gallery-image-options {
  display: block;
}

.gallery > .row > div {
  margin-bottom: 15px;
}

.gallery.gallery-widget > .row > div {
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

/* Media Items */
.media-items {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border: 2px solid #f1f1f1;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

.media-items:hover {
  border-color: #cccccc;
}

.media-items:hover .media-items-options {
  display: block;
}

.media-items .media-items-options {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px;
  display: none;
}

.media-items .media-items-content {
  padding: 40px 0 20px;
}

/* Charts */
.pie-chart .pie-avatar {
  position: absolute;
  top: 8px;
  left: 8px;
}

.chart {
  height: 360px;
}

.chart-tooltip,
.mini-chart-tooltip {
  position: absolute;
  display: none;
  color: #ffffff;
  background-color: #000000;
  padding: 4px 10px;
}

.chart-pie-label {
  font-size: 12px;
  text-align: center;
  padding: 8px 12px;
  color: #ffffff;
}

.mini-chart-tooltip {
  left: 0;
  top: 0;
  visibility: hidden;
}

.legend table td {
  padding: 4px;
  font-size: 14px;
}

/* Timeline */
.timeline {
  position: relative;
}

.timeline-header {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 0 15px;
  min-height: 60px;
  line-height: 60px;
  background-color: #ffffff;
  border-bottom: 2px solid #f0f0f0;
  z-index: 500;
}

.timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timeline-list:after {
  position: absolute;
  display: block;
  width: 2px;
  top: 0;
  left: 95px;
  bottom: 0;
  content: "";
  background-color: #f0f0f0;
  z-index: 1;
}

.timeline-header + .timeline-list:after {
  top: 60px;
}

.timeline-list li {
  position: relative;
  margin: 0;
  padding: 15px 0;
}

.timeline-list.timeline-hover li:hover {
  background-color: #f9f9f9;
}

.timeline-list .timeline-icon {
  position: absolute;
  left: 80px;
  top: 10px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 15px;
  z-index: 500;
}

.timeline-list .active .timeline-icon {
  background-color: @color_highlight;
  border-color: @color_highlight;
  color: #ffffff;
}

.timeline-list .timeline-time {
  float: left;
  width: 70px;
  text-align: right;
}

.timeline-list .timeline-content {
  margin-left: 120px;
}

.block-content-full .timeline-content {
  padding-right: 20px;
}

.media-feed {
  margin-bottom: 0;
}

.media-feed > .media {
  margin-top: 0;
  padding: 20px 20px 0;
  border-top: 1px dotted @color_light_secondary;
}

.media-feed > .media:first-child {
  border-top: none;
}

.media-feed.media-feed-hover > .media:hover {
  background-color: #f9f9f9;
}

/* Error */
#error-container {
  padding: 120px 20px;
  position: relative;
}

#error-container .error-options {
  position: absolute;
  top: 20px;
  left: 20px;
}

#error-container h1 {
  font-size: 96px;
  color: #ffffff;
  margin-bottom: 40px;
}

#error-container h2 {
  color: #cccccc;
  margin-bottom: 40px;
  line-height: 1.4;
}

#error-container form {
  padding: 20px;
  border-radius: 3px;
  background: #ffffff;
  background: url('@{assets_folder}/template/ie8_opacity_light_10.png') repeat;
  background: rgba(255, 255, 255, 0.1);
}

#error-container .form-control {
  border-color: #ffffff;
}

/* Pricing Table */
.table.table-pricing {
  background-color: #ffffff;
}

.table-pricing th,
.table-pricing td {
  text-align: center;
}

.table-pricing th {
  font-size: 24px !important;
}

.table-pricing td {
  font-size: 15px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.table-pricing .table-price {
  background-color: #f9f9f9;
}

.table-pricing.table-featured .table-price,
.table-pricing .table-price.table-featured {
  background-color: #252525;
}

.table-pricing.table-featured th,
.table-pricing th.table-featured {
  background-color: @color_highlight;
  border-bottom: 2px solid @color_dark;
  color: #ffffff;
}

.table-pricing.table-featured td,
.table-pricing td.table-featured {
  background-color: @color_dark;
  color: #ffffff;
}

/* Chat UI */
.chatui-container {
  position: relative;
}

.chatui-people,
.chatui-talk {
  overflow-y: hidden;
}

.chatui-people .chatui-header {
  font-size: 14px;
  line-height: 14px;
  color: #999999;
  text-transform: uppercase;
  padding: 5px 20px;
}

.chatui-people hr {
  border-top-color: #252525;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.chatui-people .list-group-item {
  background: transparent;
  min-height: 52px;
  border: 0;
  border-radius: 0 !important;
}

.chatui-people .list-group-item .badge {
  margin-top: 7px;
}

.chatui-people .list-group-item .list-group-item-heading {
  color: #ffffff;
  line-height: 32px;
  margin: 0;
}

.chatui-people .list-group-item:hover,
.chatui-people .list-group-item:focus {
  background: url('@{assets_folder}/template/ie8_opacity_dark_20.png') repeat;
  background: rgba(0, 0, 0, 0.2);
}

.chatui-people .list-group-item img {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.chatui-talk {
  top: 0;
  bottom: 50px;
}

.chatui-talk ul {
  padding: 15px;
  margin: 0;
  list-style: none;
}

.chatui-talk li {
  padding: 5px 10px;
  margin-bottom: 7px;
}

.chatui-talk .chatui-talk-msg {
  padding: 15px 10px;
  padding-right: 50px;
  border-left: 5px solid #333333;
  background-color: #f6f6f6;
  position: relative;
}

.chatui-talk .chatui-talk-msg.chatui-talk-msg-highlight {
  padding-left: 50px;
  border-left: none;
  border-right-width: 5px;
  border-right-style: solid;
}

.chatui-talk-msg .chatui-talk-msg-avatar {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
}

.chatui-talk-msg.chatui-talk-msg-highlight .chatui-talk-msg-avatar {
  top: 8px;
  right: auto;
  left: 8px;
}

.chatui-input {
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eeeeee;
}

/* Tasks */
.task-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.task-list li {
  padding: 15px 40px 15px 15px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
  -webkit-transition: box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
}

.task-list li:hover {
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.task-list li.in-1x {
  margin-left: 15px;
}

.task-list li.in-2x {
  margin-left: 30px;
}

.task-list li > .task-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000000;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.task-list li:hover > .task-close {
  opacity: .25;
}

.task-list li.task-done {
  opacity: .50;
}

.task-list li.task-done > label {
  text-decoration: line-through;
}
