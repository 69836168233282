.truncate-filename {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  height: 23px;
  color: inherit;
}

.btn-sidebar-exit {
  background: none;
  outline: none;
  border: none;
  display: inline;
  padding: 0;
  color: #fff;
  opacity: .3;
  margin-right: 5px;
}

.btn-sidebar-exit:hover {
  opacity: 1;
}

.btn-sidebar-exit > i {
  font-size: 14px;
}

.btn-navbar-exit {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  outline: none;
}

.price-input-group {
  max-width: 140px;
}

.price-field {
  max-width: 95px;
}

.tagsinput.without-input {
  border: none;
  padding-left: 0;
}

.price-column {
  width: 100px;
}

.orderid {
  white-space: nowrap;
}

.quantity-input {
  width: 50px;
}

.reason-label {
  margin-bottom: 5px;
}

.reason-input {
  height: 80px;
}

.order-canceled {
  background: #eee;
  opacity: .75;
}

.actions-group {
  display: flex;
}

.actions-btn {
  margin-left: 5px;
}

.actions-error {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.table-reduced {
  th {
    font-size: 13px !important;
  }

  .numeric-price {
    width: 50px;
  }
}

.col-quantity {
  max-width: 60px;
}

.warning {
  color: #ffcc00;
  margin-top: -8px;
}

.dropzone.dz-single {
  .dz-preview {
    margin: 0;
    width: 100%;
  }

  .dz-image {
    width: 100%;
  }

  .dz-progress {
    display: none;
  }
}

.ck-editor__editable {
  min-height: 300px;
}

.richtext th {
  text-align: left;
  font-weight: 700;
  background: hsla(0,0%,0%,5%);
  font-size: 13px !important;
}

.richtext th, .richtext td {
  padding: .4em !important;
  border: 1px solid #bfbfbf !important;
}

.media-feed > .media {
  padding-bottom: 20px;
}

.notification {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}

.notification__content {
  display: flex;
  margin-bottom: 5px;
}

.notification__date {
  text-align: right;
}

.notification-marker {
  width: 13px;
  display: inline-block;
  height: 13px;
  border-radius: 50%;
  margin-left: auto;
}

.notification-marker--read {
  border: 2px solid #e5e7ea;
}

.notification-marker--new {
  background: #1bbae1;
}
