/*
 *  Document   : content.less
 *  Author     : pixelcave
 *
 *  MAIN CONTENT
 *
 */

/* Content Header */
.content-header {
  background-color: #ffffff;
  border-top: 1px solid @color_light_main;
  border-bottom: 1px solid @color_light_secondary;
}

.content-header h1,
.content-header h2 {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
}

.content-header small
.content-header small {
  font-size: 17px;
}

.header-section h1 i {
  font-size: 56px;
  float: right;
  margin: 2px 0 0 10px;
  color: @color_light_main;
  margin: 0 0 0 10px;
  line-height: 64px;
}

.header-section {
  padding: 30px 10px;
}

.content-header,
.content-top {
  margin: -10px -5px 10px;
}

.content-top {
  background-color: #ffffff;
  border-bottom: 1px solid @color_light_secondary;
}

.content-header-media {
  position: relative;
  height: 248px;
  overflow: hidden;
  border-top-color: #222222;
}

.content-header-media .header-section {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  background: url('@{assets_folder}/template/ie8_opacity_dark_60.png') repeat;
  background: rgba(0, 0, 0, 0.6);
}

.content-header-media small,
.content-header-media i {
  color: #dddddd;
}

.content-header-media > img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2560px;
  height: 248px;
  margin-left: -1280px;
}

.content-header-media > .content-header-media-map {
  height: 270px;
}

/* Blocks */
.block {
  margin: 0 0 10px;
  padding: 20px 15px 1px;
  background-color: #ffffff;
  border: 1px solid @color_light_secondary;
  border-radius: @radius_blocks;
}

.block.full {
  padding: 20px 15px;
}

.block .block-content-full {
  margin: -20px -15px -1px;
}

.block .block-content-mini-padding {
  padding: 8px;
}

.block.full .block-content-full {
  margin: -20px -15px;
}

.block .tab-content .block-content-full,
.block.full .tab-content .block-content-full {
  margin-top: -19px !important;
}

.block-title {
  margin: -20px -15px 20px;
  background-color: @color_light_ultra;
  border-bottom: 1px solid @color_light_main;
  border-top-left-radius: @radius_blocks;
  border-top-right-radius: @radius_blocks;
}

.block-title h1,
.block-title h2,
.block-title h3,
.block-title h4,
.block-title h5,
.block-title h6 {
  display: inline-block;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  padding: 10px 16px 7px;
  font-weight: normal;
}

.block-title h1 small,
.block-title h2 small,
.block-title h3 small,
.block-title h4 small,
.block-title h5 small,
.block-title h6 small {
  font-size: 13px;
  color: #777777;
  font-weight: normal;
}

.block-title h1,
.block-title h2,
.block-title h3 {
  padding-left: 15px;
  padding-right: 15px;
}

.block-title .nav-tabs,
.block-options {
  min-height: 40px;
  line-height: 38px;
}

.block-title .nav-tabs {
  padding: 3px 1px 0;
  border-bottom: none;
}

.block-title .nav-tabs > li > a {
  border-bottom: none;
}

.block-title .nav-tabs {
  margin-bottom: -2px;
}

.block-title .nav-tabs > li > a {
  margin-bottom: 0;
}

.block-title .nav-tabs > li > a:hover {
  background: none;
}

.block-title .nav-tabs > li.active > a,
.block-title .nav-tabs > li.active > a:hover,
.block-title .nav-tabs > li.active > a:focus {
  border: 1px solid @color_light_main;
  border-bottom-color: #ffffff;
  background-color: #ffffff;
}

.block-title code {
  padding: 2px 3px;
}

.block-options {
  margin: 0 6px;
  line-height: 37px;
}

.block-options .label {
  display: inline-block;
  padding: 6px;
  vertical-align: middle;
  font-size: 13px;
}

.block-top {
  margin: -20px -15px 20px;
  border-bottom: 1px dotted @color_light_secondary;
}

.block-section {
  margin-bottom: 20px;
}

.block.block-fullscreen {
  position: fixed;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  z-index: 1031;
  margin-bottom: 0;
  overflow-y: auto;
}

/* Widgets */
.widget {
  background-color: #ffffff;
  margin-bottom: 10px;
}

.widget .widget-extra-full,
.widget .widget-extra {
  position: relative;
  padding: 15px;
}

.widget .widget-extra {
  padding-top: 1px;
  padding-bottom: 1px;
}

.widget .widget-content-light {
  color: #ffffff;
}

.widget .widget-content-light small {
  color: #eeeeee;
}

.widget .widget-image,
.widget .widget-icon {
  width: 64px;
  height: 64px;
}

.widget .widget-icon {
  display: inline-block;
  line-height: 64px;
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  border-radius: 32px;
}

.widget .widget-icon .gi,
.widget .widget-icon .si,
.widget .widget-icon .hi,
.widget .widget-icon .fi {
  margin-top: -3px;
}

.widget .widget-options,
.widget .widget-options-left {
  position: absolute;
  top: 5px;
  opacity: 0.5;
}

.widget .widget-options {
  right: 5px;
}

.widget .widget-options-left {
  left: 5px;
}

.widget .widget-options:hover,
.widget .widget-options-left:hover {
  opacity: 1;
}

.widget-simple {
  padding: 15px;
}

.widget-simple:before,
.widget-simple:after {
  content: " ";
  display: table;
}

.widget-simple:after {
  clear: both;
}

.widget-simple .widget-image,
.widget-simple .widget-icon {
  margin: 0 15px;
}

.widget-simple .widget-image.pull-left,
.widget-simple .widget-icon.pull-left {
  margin-left: 0;
}

.widget-simple .widget-image.pull-right,
.widget-simple .widget-icon.pull-right {
  margin-right: 0;
}

.widget-simple .widget-content {
  font-size: 18px;
  margin: 12px 0;
}

.widget-simple .widget-content small {
  display: block;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 400;
}

.widget-advanced .widget-header {
  position: relative;
  padding: 15px 15px 50px;
  height: 150px;
  overflow: hidden;
}

.widget-advanced .widget-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
}

.widget-advanced .widget-background-map {
  height: 180px;
  width: 100%;
}

.widget-advanced .widget-content-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  margin: 0;
  background: url('@{assets_folder}/template/ie8_opacity_dark_60.png') repeat;
  background: rgba(0, 0, 0, 0.6);
}

.widget-advanced .widget-main {
  position: relative;
  padding: 50px 15px 15px;
}

.widget-advanced .widget-image-container {
  position: absolute;
  display: inline-block;
  padding: 5px;
  width: 74px;
  height: 74px;
  top: -36px;
  left: 50%;
  margin-left: -36px;
  border-radius: 36px;
  background-color: #ffffff;
}

.widget-advanced .widget-header .widget-image-container {
  position: static;
  left: auto;
  top: auto;
  margin: 0;
}

.widget-advanced-alt .widget-header,
.widget-advanced-alt .widget-main {
  padding: 15px;
}

.widget-advanced-alt .widget-header {
  height: auto;
  min-height: 150px;
}

/* Link Widgets */
a.widget {
  display: block;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a.widget:hover,
a.widget:focus,
a.widget:active {
  text-decoration: none;
}

a.widget.widget-hover-effect1:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

a.widget.widget-hover-effect1:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

a.widget.widget-hover-effect2:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -5px 0 rgba(0, 0, 0, 0.2);
}

a.widget.widget-hover-effect2:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.widget.widget-hover-effect3:hover {
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}

a.widget.widget-hover-effect3:active {
  -webkit-transform: rotate(2deg) scale(0.95);
  transform: rotate(2deg) scale(0.95);
}

a.widget.widget-hover-effect4:hover {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

a.widget.widget-hover-effect4:active {
  -webkit-transform: rotate(-1deg) scale(0.95);
  transform: rotate(-1deg) scale(0.95);
}

/* Content which contains floats */
.content-float .pull-left {
  margin: 0 20px 20px 0;
}

.content-float .pull-right {
  margin: 0 0 20px 20px;
}

/* Draggable Blocks */
.draggable-blocks .block-title {
  cursor: move;
}

.draggable-placeholder {
  background-color: #dddddd;
  border: 1px dashed #999999;
  margin-bottom: 10px;
}

/* Scroll to top link */
#to-top {
  display: none;
  position: fixed;
  bottom: 55px;
  left: 5px;
  border-radius: 3px;
  padding: 0 12px;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  opacity: 0.1;
}

#to-top:hover {
  color: #ffffff;
  background-color: @color_highlight;
  text-decoration: none;
  opacity: 1;
}
