/*
 *  Document: glyphicons.less
 *
 *  Glyphicons PRO
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons.pro/glyphicons-regular.eot');
  src: url('../fonts/glyphicons.pro/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons.pro/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons.pro/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons.pro/glyphicons-regular.svg#glyphiconsregular') format('svg')
}

@font-face {
  font-family: 'Glyphicons Halflings Regular';
  src: url('../fonts/glyphicons.halflings.pro/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons.halflings.pro/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons.halflings.pro/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons.halflings.pro/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons.halflings.pro/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg')
}

@font-face {
  font-family: 'Glyphicons Social Regular';
  src: url('../fonts/glyphicons.social.pro/glyphicons-social-regular.eot');
  src: url('../fonts/glyphicons.social.pro/glyphicons-social-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons.social.pro/glyphicons-social-regular.woff') format('woff'), url('../fonts/glyphicons.social.pro/glyphicons-social-regular.ttf') format('truetype'), url('../fonts/glyphicons.social.pro/glyphicons-social-regular.svg#glyphicons_socialregular') format('svg')
}

@font-face {
  font-family: 'Glyphicons Filetypes Regular';
  src: url('../fonts/glyphicons.filetypes.pro/glyphicons-filetypes-regular.eot');
  src: url('../fonts/glyphicons.filetypes.pro/glyphicons-filetypes-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons.filetypes.pro/glyphicons-filetypes-regular.woff') format('woff'), url('../fonts/glyphicons.filetypes.pro/glyphicons-filetypes-regular.ttf') format('truetype'), url('../fonts/glyphicons.filetypes.pro/glyphicons-filetypes-regular.svg#glyphicons_filetypesregular') format('svg')
}

.fi, .gi, .hi, .si {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: .8;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.gi {
  font-family: 'Glyphicons Regular'
}

.hi {
  font-family: 'Glyphicons Halflings Regular'
}

.si {
  font-family: 'Glyphicons Social Regular'
}

.fi {
  font-family: 'Glyphicons Filetypes Regular'
}

.gi-white {
  color: #fff
}

.gi-glass:before {
  content: "\E001"
}

.gi-leaf:before {
  content: "\E002"
}

.gi-dog:before {
  content: "\E003"
}

.gi-user:before {
  content: "\E004"
}

.gi-girl:before {
  content: "\E005"
}

.gi-car:before {
  content: "\E006"
}

.gi-user_add:before {
  content: "\E007"
}

.gi-user_remove:before {
  content: "\E008"
}

.gi-film:before {
  content: "\E009"
}

.gi-magic:before {
  content: "\E010"
}

.gi-envelope:before {
  content: "\2709"
}

.gi-camera:before {
  content: "\E011"
}

.gi-heart:before {
  content: "\E013"
}

.gi-beach_umbrella:before {
  content: "\E014"
}

.gi-train:before {
  content: "\E015"
}

.gi-print:before {
  content: "\E016"
}

.gi-bin:before {
  content: "\E017"
}

.gi-music:before {
  content: "\E018"
}

.gi-note:before {
  content: "\E019"
}

.gi-heart_empty:before {
  content: "\E020"
}

.gi-home:before {
  content: "\E021"
}

.gi-snowflake:before {
  content: "\2744"
}

.gi-fire:before {
  content: "\E023"
}

.gi-magnet:before {
  content: "\E024"
}

.gi-parents:before {
  content: "\E025"
}

.gi-binoculars:before {
  content: "\E026"
}

.gi-road:before {
  content: "\E027"
}

.gi-search:before {
  content: "\E028"
}

.gi-cars:before {
  content: "\E029"
}

.gi-notes_2:before {
  content: "\E030"
}

.gi-pencil:before {
  content: "\270F"
}

.gi-bus:before {
  content: "\E032"
}

.gi-wifi_alt:before {
  content: "\E033"
}

.gi-luggage:before {
  content: "\E034"
}

.gi-old_man:before {
  content: "\E035"
}

.gi-woman:before {
  content: "\E036"
}

.gi-file:before {
  content: "\E037"
}

.gi-coins:before {
  content: "\E038"
}

.gi-airplane:before {
  content: "\2708"
}

.gi-notes:before {
  content: "\E040"
}

.gi-stats:before {
  content: "\E041"
}

.gi-charts:before {
  content: "\E042"
}

.gi-pie_chart:before {
  content: "\E043"
}

.gi-group:before {
  content: "\E044"
}

.gi-keys:before {
  content: "\E045"
}

.gi-calendar:before {
  content: "\E046"
}

.gi-router:before {
  content: "\E047"
}

.gi-camera_small:before {
  content: "\E048"
}

.gi-dislikes:before {
  content: "\E049"
}

.gi-star:before {
  content: "\E050"
}

.gi-link:before {
  content: "\E051"
}

.gi-eye_open:before {
  content: "\E052"
}

.gi-eye_close:before {
  content: "\E053"
}

.gi-alarm:before {
  content: "\E054"
}

.gi-clock:before {
  content: "\E055"
}

.gi-stopwatch:before {
  content: "\E056"
}

.gi-projector:before {
  content: "\E057"
}

.gi-history:before {
  content: "\E058"
}

.gi-truck:before {
  content: "\E059"
}

.gi-cargo:before {
  content: "\E060"
}

.gi-compass:before {
  content: "\E061"
}

.gi-keynote:before {
  content: "\E062"
}

.gi-paperclip:before {
  content: "\E063"
}

.gi-power:before {
  content: "\E064"
}

.gi-lightbulb:before {
  content: "\E065"
}

.gi-tag:before {
  content: "\E066"
}

.gi-tags:before {
  content: "\E067"
}

.gi-cleaning:before {
  content: "\E068"
}

.gi-ruller:before {
  content: "\E069"
}

.gi-gift:before {
  content: "\E070"
}

.gi-umbrella:before {
  content: "\2602"
}

.gi-book:before {
  content: "\E072"
}

.gi-bookmark:before {
  content: "\E073"
}

.gi-wifi:before {
  content: "\E074"
}

.gi-cup:before {
  content: "\E075"
}

.gi-stroller:before {
  content: "\E076"
}

.gi-headphones:before {
  content: "\E077"
}

.gi-headset:before {
  content: "\E078"
}

.gi-warning_sign:before {
  content: "\E079"
}

.gi-signal:before {
  content: "\E080"
}

.gi-retweet:before {
  content: "\E081"
}

.gi-refresh:before {
  content: "\E082"
}

.gi-roundabout:before {
  content: "\E083"
}

.gi-random:before {
  content: "\E084"
}

.gi-heat:before {
  content: "\E085"
}

.gi-repeat:before {
  content: "\E086"
}

.gi-display:before {
  content: "\E087"
}

.gi-log_book:before {
  content: "\E088"
}

.gi-address_book:before {
  content: "\E089"
}

.gi-building:before {
  content: "\E090"
}

.gi-eyedropper:before {
  content: "\E091"
}

.gi-adjust:before {
  content: "\E092"
}

.gi-tint:before {
  content: "\E093"
}

.gi-crop:before {
  content: "\E094"
}

.gi-vector_path_square:before {
  content: "\E095"
}

.gi-vector_path_circle:before {
  content: "\E096"
}

.gi-vector_path_polygon:before {
  content: "\E097"
}

.gi-vector_path_line:before {
  content: "\E098"
}

.gi-vector_path_curve:before {
  content: "\E099"
}

.gi-vector_path_all:before {
  content: "\E100"
}

.gi-font:before {
  content: "\E101"
}

.gi-italic:before {
  content: "\E102"
}

.gi-bold:before {
  content: "\E103"
}

.gi-text_underline:before {
  content: "\E104"
}

.gi-text_strike:before {
  content: "\E105"
}

.gi-text_height:before {
  content: "\E106"
}

.gi-text_width:before {
  content: "\E107"
}

.gi-text_resize:before {
  content: "\E108"
}

.gi-left_indent:before {
  content: "\E109"
}

.gi-right_indent:before {
  content: "\E110"
}

.gi-align_left:before {
  content: "\E111"
}

.gi-align_center:before {
  content: "\E112"
}

.gi-align_right:before {
  content: "\E113"
}

.gi-justify:before {
  content: "\E114"
}

.gi-list:before {
  content: "\E115"
}

.gi-text_smaller:before {
  content: "\E116"
}

.gi-text_bigger:before {
  content: "\E117"
}

.gi-embed:before {
  content: "\E118"
}

.gi-embed_close:before {
  content: "\E119"
}

.gi-table:before {
  content: "\E120"
}

.gi-message_full:before {
  content: "\E121"
}

.gi-message_empty:before {
  content: "\E122"
}

.gi-message_in:before {
  content: "\E123"
}

.gi-message_out:before {
  content: "\E124"
}

.gi-message_plus:before {
  content: "\E125"
}

.gi-message_minus:before {
  content: "\E126"
}

.gi-message_ban:before {
  content: "\E127"
}

.gi-message_flag:before {
  content: "\E128"
}

.gi-message_lock:before {
  content: "\E129"
}

.gi-message_new:before {
  content: "\E130"
}

.gi-inbox:before {
  content: "\E131"
}

.gi-inbox_plus:before {
  content: "\E132"
}

.gi-inbox_minus:before {
  content: "\E133"
}

.gi-inbox_lock:before {
  content: "\E134"
}

.gi-inbox_in:before {
  content: "\E135"
}

.gi-inbox_out:before {
  content: "\E136"
}

.gi-cogwheel:before {
  content: "\E137"
}

.gi-cogwheels:before {
  content: "\E138"
}

.gi-picture:before {
  content: "\E139"
}

.gi-adjust_alt:before {
  content: "\E140"
}

.gi-database_lock:before {
  content: "\E141"
}

.gi-database_plus:before {
  content: "\E142"
}

.gi-database_minus:before {
  content: "\E143"
}

.gi-database_ban:before {
  content: "\E144"
}

.gi-folder_open:before {
  content: "\E145"
}

.gi-folder_plus:before {
  content: "\E146"
}

.gi-folder_minus:before {
  content: "\E147"
}

.gi-folder_lock:before {
  content: "\E148"
}

.gi-folder_flag:before {
  content: "\E149"
}

.gi-folder_new:before {
  content: "\E150"
}

.gi-edit:before {
  content: "\E151"
}

.gi-new_window:before {
  content: "\E152"
}

.gi-check:before {
  content: "\E153"
}

.gi-unchecked:before {
  content: "\E154"
}

.gi-more_windows:before {
  content: "\E155"
}

.gi-show_big_thumbnails:before {
  content: "\E156"
}

.gi-show_thumbnails:before {
  content: "\E157"
}

.gi-show_thumbnails_with_lines:before {
  content: "\E158"
}

.gi-show_lines:before {
  content: "\E159"
}

.gi-playlist:before {
  content: "\E160"
}

.gi-imac:before {
  content: "\E161"
}

.gi-macbook:before {
  content: "\E162"
}

.gi-ipad:before {
  content: "\E163"
}

.gi-iphone:before {
  content: "\E164"
}

.gi-iphone_transfer:before {
  content: "\E165"
}

.gi-iphone_exchange:before {
  content: "\E166"
}

.gi-ipod:before {
  content: "\E167"
}

.gi-ipod_shuffle:before {
  content: "\E168"
}

.gi-ear_plugs:before {
  content: "\E169"
}

.gi-record:before {
  content: "\E170"
}

.gi-step_backward:before {
  content: "\E171"
}

.gi-fast_backward:before {
  content: "\E172"
}

.gi-rewind:before {
  content: "\E173"
}

.gi-play:before {
  content: "\E174"
}

.gi-pause:before {
  content: "\E175"
}

.gi-stop:before {
  content: "\E176"
}

.gi-forward:before {
  content: "\E177"
}

.gi-fast_forward:before {
  content: "\E178"
}

.gi-step_forward:before {
  content: "\E179"
}

.gi-eject:before {
  content: "\E180"
}

.gi-facetime_video:before {
  content: "\E181"
}

.gi-download_alt:before {
  content: "\E182"
}

.gi-mute:before {
  content: "\E183"
}

.gi-volume_down:before {
  content: "\E184"
}

.gi-volume_up:before {
  content: "\E185"
}

.gi-screenshot:before {
  content: "\E186"
}

.gi-move:before {
  content: "\E187"
}

.gi-more:before {
  content: "\E188"
}

.gi-brightness_reduce:before {
  content: "\E189"
}

.gi-brightness_increase:before {
  content: "\E190"
}

.gi-circle_plus:before {
  content: "\E191"
}

.gi-circle_minus:before {
  content: "\E192"
}

.gi-circle_remove:before {
  content: "\E193"
}

.gi-circle_ok:before {
  content: "\E194"
}

.gi-circle_question_mark:before {
  content: "\E195"
}

.gi-circle_info:before {
  content: "\E196"
}

.gi-circle_exclamation_mark:before {
  content: "\E197"
}

.gi-remove:before {
  content: "\E198"
}

.gi-ok:before {
  content: "\E199"
}

.gi-ban:before {
  content: "\E200"
}

.gi-download:before {
  content: "\E201"
}

.gi-upload:before {
  content: "\E202"
}

.gi-shopping_cart:before {
  content: "\E203"
}

.gi-lock:before {
  content: "\E204"
}

.gi-unlock:before {
  content: "\E205"
}

.gi-electricity:before {
  content: "\E206"
}

.gi-ok_2:before {
  content: "\E207"
}

.gi-remove_2:before {
  content: "\E208"
}

.gi-cart_out:before {
  content: "\E209"
}

.gi-cart_in:before {
  content: "\E210"
}

.gi-left_arrow:before {
  content: "\E211"
}

.gi-right_arrow:before {
  content: "\E212"
}

.gi-down_arrow:before {
  content: "\E213"
}

.gi-up_arrow:before {
  content: "\E214"
}

.gi-resize_small:before {
  content: "\E215"
}

.gi-resize_full:before {
  content: "\E216"
}

.gi-circle_arrow_left:before {
  content: "\E217"
}

.gi-circle_arrow_right:before {
  content: "\E218"
}

.gi-circle_arrow_top:before {
  content: "\E219"
}

.gi-circle_arrow_down:before {
  content: "\E220"
}

.gi-play_button:before {
  content: "\E221"
}

.gi-unshare:before {
  content: "\E222"
}

.gi-share:before {
  content: "\E223"
}

.gi-chevron-right:before {
  content: "\E224"
}

.gi-chevron-left:before {
  content: "\E225"
}

.gi-bluetooth:before {
  content: "\E226"
}

.gi-euro:before {
  content: "\20AC"
}

.gi-usd:before {
  content: "\E228"
}

.gi-gbp:before {
  content: "\E229"
}

.gi-retweet_2:before {
  content: "\E230"
}

.gi-moon:before {
  content: "\E231"
}

.gi-sun:before {
  content: "\2609"
}

.gi-cloud:before {
  content: "\2601"
}

.gi-direction:before {
  content: "\E234"
}

.gi-brush:before {
  content: "\E235"
}

.gi-pen:before {
  content: "\E236"
}

.gi-zoom_in:before {
  content: "\E237"
}

.gi-zoom_out:before {
  content: "\E238"
}

.gi-pin:before {
  content: "\E239"
}

.gi-albums:before {
  content: "\E240"
}

.gi-rotation_lock:before {
  content: "\E241"
}

.gi-flash:before {
  content: "\E242"
}

.gi-google_maps:before {
  content: "\E243"
}

.gi-anchor:before {
  content: "\2693"
}

.gi-conversation:before {
  content: "\E245"
}

.gi-chat:before {
  content: "\E246"
}

.gi-male:before {
  content: "\E247"
}

.gi-female:before {
  content: "\E248"
}

.gi-asterisk:before {
  content: "\002A"
}

.gi-divide:before {
  content: "\00F7"
}

.gi-snorkel_diving:before {
  content: "\E251"
}

.gi-scuba_diving:before {
  content: "\E252"
}

.gi-oxygen_bottle:before {
  content: "\E253"
}

.gi-fins:before {
  content: "\E254"
}

.gi-fishes:before {
  content: "\E255"
}

.gi-boat:before {
  content: "\E256"
}

.gi-delete:before {
  content: "\E257"
}

.gi-sheriffs_star:before {
  content: "\E258"
}

.gi-qrcode:before {
  content: "\E259"
}

.gi-barcode:before {
  content: "\E260"
}

.gi-pool:before {
  content: "\E261"
}

.gi-buoy:before {
  content: "\E262"
}

.gi-spade:before {
  content: "\E263"
}

.gi-bank:before {
  content: "\E264"
}

.gi-vcard:before {
  content: "\E265"
}

.gi-electrical_plug:before {
  content: "\E266"
}

.gi-flag:before {
  content: "\E267"
}

.gi-credit_card:before {
  content: "\E268"
}

.gi-keyboard-wireless:before {
  content: "\E269"
}

.gi-keyboard-wired:before {
  content: "\E270"
}

.gi-shield:before {
  content: "\E271"
}

.gi-ring:before {
  content: "\02DA"
}

.gi-cake:before {
  content: "\E273"
}

.gi-drink:before {
  content: "\E274"
}

.gi-beer:before {
  content: "\E275"
}

.gi-fast_food:before {
  content: "\E276"
}

.gi-cutlery:before {
  content: "\E277"
}

.gi-pizza:before {
  content: "\E278"
}

.gi-birthday_cake:before {
  content: "\E279"
}

.gi-tablet:before {
  content: "\E280"
}

.gi-settings:before {
  content: "\E281"
}

.gi-bullets:before {
  content: "\E282"
}

.gi-cardio:before {
  content: "\E283"
}

.gi-t-shirt:before {
  content: "\E284"
}

.gi-pants:before {
  content: "\E285"
}

.gi-sweater:before {
  content: "\E286"
}

.gi-fabric:before {
  content: "\E287"
}

.gi-leather:before {
  content: "\E288"
}

.gi-scissors:before {
  content: "\E289"
}

.gi-bomb:before {
  content: "\E290"
}

.gi-skull:before {
  content: "\E291"
}

.gi-celebration:before {
  content: "\E292"
}

.gi-tea_kettle:before {
  content: "\E293"
}

.gi-french_press:before {
  content: "\E294"
}

.gi-coffee_cup:before {
  content: "\E295"
}

.gi-pot:before {
  content: "\E296"
}

.gi-grater:before {
  content: "\E297"
}

.gi-kettle:before {
  content: "\E298"
}

.gi-hospital:before {
  content: "\E299"
}

.gi-hospital_h:before {
  content: "\E300"
}

.gi-microphone:before {
  content: "\E301"
}

.gi-webcam:before {
  content: "\E302"
}

.gi-temple_christianity_church:before {
  content: "\E303"
}

.gi-temple_islam:before {
  content: "\E304"
}

.gi-temple_hindu:before {
  content: "\E305"
}

.gi-temple_buddhist:before {
  content: "\E306"
}

.gi-bicycle:before {
  content: "\E307"
}

.gi-life_preserver:before {
  content: "\E308"
}

.gi-share_alt:before {
  content: "\E309"
}

.gi-comments:before {
  content: "\E310"
}

.gi-flower:before {
  content: "\2698"
}

.gi-baseball:before {
  content: "\26BE"
}

.gi-rugby:before {
  content: "\E313"
}

.gi-ax:before {
  content: "\E314"
}

.gi-table_tennis:before {
  content: "\E315"
}

.gi-bowling:before {
  content: "\E316"
}

.gi-tree_conifer:before {
  content: "\E317"
}

.gi-tree_deciduous:before {
  content: "\E318"
}

.gi-more_items:before {
  content: "\E319"
}

.gi-sort:before {
  content: "\E320"
}

.gi-filter:before {
  content: "\E321"
}

.gi-gamepad:before {
  content: "\E322"
}

.gi-playing_dices:before {
  content: "\E323"
}

.gi-calculator:before {
  content: "\E324"
}

.gi-tie:before {
  content: "\E325"
}

.gi-wallet:before {
  content: "\E326"
}

.gi-piano:before {
  content: "\E327"
}

.gi-sampler:before {
  content: "\E328"
}

.gi-podium:before {
  content: "\E329"
}

.gi-soccer_ball:before {
  content: "\E330"
}

.gi-blog:before {
  content: "\E331"
}

.gi-dashboard:before {
  content: "\E332"
}

.gi-certificate:before {
  content: "\E333"
}

.gi-bell:before {
  content: "\E334"
}

.gi-candle:before {
  content: "\E335"
}

.gi-pushpin:before {
  content: "\E336"
}

.gi-iphone_shake:before {
  content: "\E337"
}

.gi-pin_flag:before {
  content: "\E338"
}

.gi-turtle:before {
  content: "\E339"
}

.gi-rabbit:before {
  content: "\E340"
}

.gi-globe:before {
  content: "\E341"
}

.gi-briefcase:before {
  content: "\E342"
}

.gi-hdd:before {
  content: "\E343"
}

.gi-thumbs_up:before {
  content: "\E344"
}

.gi-thumbs_down:before {
  content: "\E345"
}

.gi-hand_right:before {
  content: "\E346"
}

.gi-hand_left:before {
  content: "\E347"
}

.gi-hand_up:before {
  content: "\E348"
}

.gi-hand_down:before {
  content: "\E349"
}

.gi-fullscreen:before {
  content: "\E350"
}

.gi-shopping_bag:before {
  content: "\E351"
}

.gi-book_open:before {
  content: "\E352"
}

.gi-nameplate:before {
  content: "\E353"
}

.gi-nameplate_alt:before {
  content: "\E354"
}

.gi-vases:before {
  content: "\E355"
}

.gi-bullhorn:before {
  content: "\E356"
}

.gi-dumbbell:before {
  content: "\E357"
}

.gi-suitcase:before {
  content: "\E358"
}

.gi-file_import:before {
  content: "\E359"
}

.gi-file_export:before {
  content: "\E360"
}

.gi-bug:before {
  content: "\E361"
}

.gi-crown:before {
  content: "\E362"
}

.gi-smoking:before {
  content: "\E363"
}

.gi-cloud-download:before {
  content: "\E364"
}

.gi-cloud-upload:before {
  content: "\E365"
}

.gi-restart:before {
  content: "\E366"
}

.gi-security_camera:before {
  content: "\E367"
}

.gi-expand:before {
  content: "\E368"
}

.gi-collapse:before {
  content: "\E369"
}

.gi-collapse_top:before {
  content: "\E370"
}

.gi-globe_af:before {
  content: "\E371"
}

.gi-global:before {
  content: "\E372"
}

.gi-spray:before {
  content: "\E373"
}

.gi-nails:before {
  content: "\E374"
}

.gi-claw_hammer:before {
  content: "\E375"
}

.gi-classic_hammer:before {
  content: "\E376"
}

.gi-hand_saw:before {
  content: "\E377"
}

.gi-riflescope:before {
  content: "\E378"
}

.gi-electrical_socket_eu:before {
  content: "\E379"
}

.gi-electrical_socket_us:before {
  content: "\E380"
}

.gi-message_forward:before {
  content: "\E381"
}

.gi-coat_hanger:before {
  content: "\E382"
}

.gi-dress:before {
  content: "\E383"
}

.gi-bathrobe:before {
  content: "\E384"
}

.gi-shirt:before {
  content: "\E385"
}

.gi-underwear:before {
  content: "\E386"
}

.gi-log_in:before {
  content: "\E387"
}

.gi-log_out:before {
  content: "\E388"
}

.gi-exit:before {
  content: "\E389"
}

.gi-new_window_alt:before {
  content: "\E390"
}

.gi-video_sd:before {
  content: "\E391"
}

.gi-video_hd:before {
  content: "\E392"
}

.gi-subtitles:before {
  content: "\E393"
}

.gi-sound_stereo:before {
  content: "\E394"
}

.gi-sound_dolby:before {
  content: "\E395"
}

.gi-sound_5_1:before {
  content: "\E396"
}

.gi-sound_6_1:before {
  content: "\E397"
}

.gi-sound_7_1:before {
  content: "\E398"
}

.gi-copyright_mark:before {
  content: "\E399"
}

.gi-registration_mark:before {
  content: "\E400"
}

.gi-radar:before {
  content: "\E401"
}

.gi-skateboard:before {
  content: "\E402"
}

.gi-golf_course:before {
  content: "\E403"
}

.gi-sorting:before {
  content: "\E404"
}

.gi-sort-by-alphabet:before {
  content: "\E405"
}

.gi-sort-by-alphabet-alt:before {
  content: "\E406"
}

.gi-sort-by-order:before {
  content: "\E407"
}

.gi-sort-by-order-alt:before {
  content: "\E408"
}

.gi-sort-by-attributes:before {
  content: "\E409"
}

.gi-sort-by-attributes-alt:before {
  content: "\E410"
}

.gi-compressed:before {
  content: "\E411"
}

.gi-package:before {
  content: "\E412"
}

.gi-cloud_plus:before {
  content: "\E413"
}

.gi-cloud_minus:before {
  content: "\E414"
}

.gi-disk_save:before {
  content: "\E415"
}

.gi-disk_open:before {
  content: "\E416"
}

.gi-disk_saved:before {
  content: "\E417"
}

.gi-disk_remove:before {
  content: "\E418"
}

.gi-disk_import:before {
  content: "\E419"
}

.gi-disk_export:before {
  content: "\E420"
}

.gi-tower:before {
  content: "\E421"
}

.gi-send:before {
  content: "\E422"
}

.gi-git_branch:before {
  content: "\E423"
}

.gi-git_create:before {
  content: "\E424"
}

.gi-git_private:before {
  content: "\E425"
}

.gi-git_delete:before {
  content: "\E426"
}

.gi-git_merge:before {
  content: "\E427"
}

.gi-git_pull_request:before {
  content: "\E428"
}

.gi-git_compare:before {
  content: "\E429"
}

.gi-git_commit:before {
  content: "\E430"
}

.gi-construction_cone:before {
  content: "\E431"
}

.gi-shoe_steps:before {
  content: "\E432"
}

.gi-plus:before {
  content: "\002B"
}

.gi-minus:before {
  content: "\2212"
}

.gi-redo:before {
  content: "\E435"
}

.gi-undo:before {
  content: "\E436"
}

.gi-golf:before {
  content: "\E437"
}

.gi-hockey:before {
  content: "\E438"
}

.gi-pipe:before {
  content: "\E439"
}

.gi-wrench:before {
  content: "\E440"
}

.gi-folder_closed:before {
  content: "\E441"
}

.gi-phone_alt:before {
  content: "\E442"
}

.gi-earphone:before {
  content: "\E443"
}

.gi-floppy_disk:before {
  content: "\E444"
}

.gi-floppy_saved:before {
  content: "\E445"
}

.gi-floppy_remove:before {
  content: "\E446"
}

.gi-floppy_save:before {
  content: "\E447"
}

.gi-floppy_open:before {
  content: "\E448"
}

.gi-translate:before {
  content: "\E449"
}

.gi-fax:before {
  content: "\E450"
}

.gi-factory:before {
  content: "\E451"
}

.gi-shop_window:before {
  content: "\E452"
}

.gi-shop:before {
  content: "\E453"
}

.gi-kiosk:before {
  content: "\E454"
}

.gi-kiosk_wheels:before {
  content: "\E455"
}

.gi-kiosk_light:before {
  content: "\E456"
}

.gi-kiosk_food:before {
  content: "\E457"
}

.gi-transfer:before {
  content: "\E458"
}

.gi-money:before {
  content: "\E459"
}

.gi-header:before {
  content: "\E460"
}

.gi-blacksmith:before {
  content: "\E461"
}

.gi-saw_blade:before {
  content: "\E462"
}

.gi-basketball:before {
  content: "\E463"
}

.gi-server:before {
  content: "\E464"
}

.gi-server_plus:before {
  content: "\E465"
}

.gi-server_minus:before {
  content: "\E466"
}

.gi-server_ban:before {
  content: "\E467"
}

.gi-server_flag:before {
  content: "\E468"
}

.gi-server_lock:before {
  content: "\E469"
}

.gi-server_new:before {
  content: "\E470"
}

.hi-glass:before {
  content: "\E001"
}

.hi-music:before {
  content: "\E002"
}

.hi-search:before {
  content: "\E003"
}

.hi-envelope:before {
  content: "\2709"
}

.hi-heart:before {
  content: "\E005"
}

.hi-star:before {
  content: "\E006"
}

.hi-star-empty:before {
  content: "\E007"
}

.hi-user:before {
  content: "\E008"
}

.hi-film:before {
  content: "\E009"
}

.hi-th-large:before {
  content: "\E010"
}

.hi-th:before {
  content: "\E011"
}

.hi-th-list:before {
  content: "\E012"
}

.hi-ok:before {
  content: "\E013"
}

.hi-remove:before {
  content: "\E014"
}

.hi-zoom-in:before {
  content: "\E015"
}

.hi-zoom-out:before {
  content: "\E016"
}

.hi-off:before {
  content: "\E017"
}

.hi-signal:before {
  content: "\E018"
}

.hi-cog:before {
  content: "\E019"
}

.hi-trash:before {
  content: "\E020"
}

.hi-home:before {
  content: "\E021"
}

.hi-file:before {
  content: "\E022"
}

.hi-time:before {
  content: "\E023"
}

.hi-road:before {
  content: "\E024"
}

.hi-download-alt:before {
  content: "\E025"
}

.hi-download:before {
  content: "\E026"
}

.hi-upload:before {
  content: "\E027"
}

.hi-inbox:before {
  content: "\E028"
}

.hi-play-circle:before {
  content: "\E029"
}

.hi-repeat:before {
  content: "\E030"
}

.hi-refresh:before {
  content: "\E031"
}

.hi-list-alt:before {
  content: "\E032"
}

.hi-lock:before {
  content: "\E033"
}

.hi-flag:before {
  content: "\E034"
}

.hi-headphones:before {
  content: "\E035"
}

.hi-volume-off:before {
  content: "\E036"
}

.hi-volume-down:before {
  content: "\E037"
}

.hi-volume-up:before {
  content: "\E038"
}

.hi-qrcode:before {
  content: "\E039"
}

.hi-barcode:before {
  content: "\E040"
}

.hi-tag:before {
  content: "\E041"
}

.hi-tags:before {
  content: "\E042"
}

.hi-book:before {
  content: "\E043"
}

.hi-bookmark:before {
  content: "\E044"
}

.hi-print:before {
  content: "\E045"
}

.hi-camera:before {
  content: "\E046"
}

.hi-font:before {
  content: "\E047"
}

.hi-bold:before {
  content: "\E048"
}

.hi-italic:before {
  content: "\E049"
}

.hi-text-height:before {
  content: "\E050"
}

.hi-text-width:before {
  content: "\E051"
}

.hi-align-left:before {
  content: "\E052"
}

.hi-align-center:before {
  content: "\E053"
}

.hi-align-right:before {
  content: "\E054"
}

.hi-align-justify:before {
  content: "\E055"
}

.hi-list:before {
  content: "\E056"
}

.hi-indent-left:before {
  content: "\E057"
}

.hi-indent-right:before {
  content: "\E058"
}

.hi-facetime-video:before {
  content: "\E059"
}

.hi-picture:before {
  content: "\E060"
}

.hi-pencil:before {
  content: "\270F"
}

.hi-map-marker:before {
  content: "\E062"
}

.hi-adjust:before {
  content: "\E063"
}

.hi-tint:before {
  content: "\E064"
}

.hi-edit:before {
  content: "\E065"
}

.hi-share:before {
  content: "\E066"
}

.hi-check:before {
  content: "\E067"
}

.hi-move:before {
  content: "\E068"
}

.hi-step-backward:before {
  content: "\E069"
}

.hi-fast-backward:before {
  content: "\E070"
}

.hi-backward:before {
  content: "\E071"
}

.hi-play:before {
  content: "\E072"
}

.hi-pause:before {
  content: "\E073"
}

.hi-stop:before {
  content: "\E074"
}

.hi-forward:before {
  content: "\E075"
}

.hi-fast-forward:before {
  content: "\E076"
}

.hi-step-forward:before {
  content: "\E077"
}

.hi-eject:before {
  content: "\E078"
}

.hi-chevron-left:before {
  content: "\E079"
}

.hi-chevron-right:before {
  content: "\E080"
}

.hi-plus-sign:before {
  content: "\E081"
}

.hi-minus-sign:before {
  content: "\E082"
}

.hi-remove-sign:before {
  content: "\E083"
}

.hi-ok-sign:before {
  content: "\E084"
}

.hi-question-sign:before {
  content: "\E085"
}

.hi-info-sign:before {
  content: "\E086"
}

.hi-screenshot:before {
  content: "\E087"
}

.hi-remove-circle:before {
  content: "\E088"
}

.hi-ok-circle:before {
  content: "\E089"
}

.hi-ban-circle:before {
  content: "\E090"
}

.hi-arrow-left:before {
  content: "\E091"
}

.hi-arrow-right:before {
  content: "\E092"
}

.hi-arrow-up:before {
  content: "\E093"
}

.hi-arrow-down:before {
  content: "\E094"
}

.hi-share-alt:before {
  content: "\E095"
}

.hi-resize-full:before {
  content: "\E096"
}

.hi-resize-small:before {
  content: "\E097"
}

.hi-plus:before {
  content: "\002B"
}

.hi-minus:before {
  content: "\2212"
}

.hi-asterisk:before {
  content: "\002A"
}

.hi-exclamation-sign:before {
  content: "\E101"
}

.hi-gift:before {
  content: "\E102"
}

.hi-leaf:before {
  content: "\E103"
}

.hi-fire:before {
  content: "\E104"
}

.hi-eye-open:before {
  content: "\E105"
}

.hi-eye-close:before {
  content: "\E106"
}

.hi-warning-sign:before {
  content: "\E107"
}

.hi-plane:before {
  content: "\E108"
}

.hi-calendar:before {
  content: "\E109"
}

.hi-random:before {
  content: "\E110"
}

.hi-comments:before {
  content: "\E111"
}

.hi-magnet:before {
  content: "\E112"
}

.hi-chevron-up:before {
  content: "\E113"
}

.hi-chevron-down:before {
  content: "\E114"
}

.hi-retweet:before {
  content: "\E115"
}

.hi-shopping-cart:before {
  content: "\E116"
}

.hi-folder-close:before {
  content: "\E117"
}

.hi-folder-open:before {
  content: "\E118"
}

.hi-resize-vertical:before {
  content: "\E119"
}

.hi-resize-horizontal:before {
  content: "\E120"
}

.hi-hdd:before {
  content: "\E121"
}

.hi-bullhorn:before {
  content: "\E122"
}

.hi-bell:before {
  content: "\E123"
}

.hi-certificate:before {
  content: "\E124"
}

.hi-thumbs-up:before {
  content: "\E125"
}

.hi-thumbs-down:before {
  content: "\E126"
}

.hi-hand-right:before {
  content: "\E127"
}

.hi-hand-left:before {
  content: "\E128"
}

.hi-hand-top:before {
  content: "\E129"
}

.hi-hand-down:before {
  content: "\E130"
}

.hi-circle-arrow-right:before {
  content: "\E131"
}

.hi-circle-arrow-left:before {
  content: "\E132"
}

.hi-circle-arrow-top:before {
  content: "\E133"
}

.hi-circle-arrow-down:before {
  content: "\E134"
}

.hi-globe:before {
  content: "\E135"
}

.hi-wrench:before {
  content: "\E136"
}

.hi-tasks:before {
  content: "\E137"
}

.hi-filter:before {
  content: "\E138"
}

.hi-briefcase:before {
  content: "\E139"
}

.hi-fullscreen:before {
  content: "\E140"
}

.hi-dashboard:before {
  content: "\E141"
}

.hi-paperclip:before {
  content: "\E142"
}

.hi-heart-empty:before {
  content: "\E143"
}

.hi-link:before {
  content: "\E144"
}

.hi-phone:before {
  content: "\E145"
}

.hi-pushpin:before {
  content: "\E146"
}

.hi-euro:before {
  content: "\20AC"
}

.hi-usd:before {
  content: "\E148"
}

.hi-gbp:before {
  content: "\E149"
}

.hi-sort:before {
  content: "\E150"
}

.hi-sort-by-alphabet:before {
  content: "\E151"
}

.hi-sort-by-alphabet-alt:before {
  content: "\E152"
}

.hi-sort-by-order:before {
  content: "\E153"
}

.hi-sort-by-order-alt:before {
  content: "\E154"
}

.hi-sort-by-attributes:before {
  content: "\E155"
}

.hi-sort-by-attributes-alt:before {
  content: "\E156"
}

.hi-unchecked:before {
  content: "\E157"
}

.hi-expand:before {
  content: "\E158"
}

.hi-collapse:before {
  content: "\E159"
}

.hi-collapse-top:before {
  content: "\E160"
}

.hi-log_in:before {
  content: "\E161"
}

.hi-flash:before {
  content: "\E162"
}

.hi-log_out:before {
  content: "\E163"
}

.hi-new_window:before {
  content: "\E164"
}

.hi-record:before {
  content: "\E165"
}

.hi-save:before {
  content: "\E166"
}

.hi-open:before {
  content: "\E167"
}

.hi-saved:before {
  content: "\E168"
}

.hi-import:before {
  content: "\E169"
}

.hi-export:before {
  content: "\E170"
}

.hi-send:before {
  content: "\E171"
}

.hi-floppy_disk:before {
  content: "\E172"
}

.hi-floppy_saved:before {
  content: "\E173"
}

.hi-floppy_remove:before {
  content: "\E174"
}

.hi-floppy_save:before {
  content: "\E175"
}

.hi-floppy_open:before {
  content: "\E176"
}

.hi-credit_card:before {
  content: "\E177"
}

.hi-transfer:before {
  content: "\E178"
}

.hi-cutlery:before {
  content: "\E179"
}

.hi-header:before {
  content: "\E180"
}

.hi-compressed:before {
  content: "\E181"
}

.hi-earphone:before {
  content: "\E182"
}

.hi-phone_alt:before {
  content: "\E183"
}

.hi-tower:before {
  content: "\E184"
}

.hi-stats:before {
  content: "\E185"
}

.hi-sd_video:before {
  content: "\E186"
}

.hi-hd_video:before {
  content: "\E187"
}

.hi-subtitles:before {
  content: "\E188"
}

.hi-sound_stereo:before {
  content: "\E189"
}

.hi-sound_dolby:before {
  content: "\E190"
}

.hi-sound_5_1:before {
  content: "\E191"
}

.hi-sound_6_1:before {
  content: "\E192"
}

.hi-sound_7_1:before {
  content: "\E193"
}

.hi-copyright_mark:before {
  content: "\E194"
}

.hi-registration_mark:before {
  content: "\E195"
}

.hi-cloud:before {
  content: "\2601"
}

.hi-cloud_download:before {
  content: "\E197"
}

.hi-cloud_upload:before {
  content: "\E198"
}

.hi-tree_conifer:before {
  content: "\E199"
}

.hi-tree_deciduous:before {
  content: "\E200"
}

.si-pinterest:before {
  content: "\E001"
}

.si-dropbox:before {
  content: "\E002"
}

.si-google_plus:before {
  content: "\E003"
}

.si-jolicloud:before {
  content: "\E004"
}

.si-yahoo:before {
  content: "\E005"
}

.si-blogger:before {
  content: "\E006"
}

.si-picasa:before {
  content: "\E007"
}

.si-amazon:before {
  content: "\E008"
}

.si-tumblr:before {
  content: "\E009"
}

.si-wordpress:before {
  content: "\E010"
}

.si-instapaper:before {
  content: "\E011"
}

.si-evernote:before {
  content: "\E012"
}

.si-xing:before {
  content: "\E013"
}

.si-zootool:before {
  content: "\E014"
}

.si-dribbble:before {
  content: "\E015"
}

.si-deviantart:before {
  content: "\E016"
}

.si-read_it_later:before {
  content: "\E017"
}

.si-linked_in:before {
  content: "\E018"
}

.si-forrst:before {
  content: "\E019"
}

.si-pinboard:before {
  content: "\E020"
}

.si-behance:before {
  content: "\E021"
}

.si-github:before {
  content: "\E022"
}

.si-youtube:before {
  content: "\E023"
}

.si-skitch:before {
  content: "\E024"
}

.si-foursquare:before {
  content: "\E025"
}

.si-quora:before {
  content: "\E026"
}

.si-badoo:before {
  content: "\E027"
}

.si-spotify:before {
  content: "\E028"
}

.si-stumbleupon:before {
  content: "\E029"
}

.si-readability:before {
  content: "\E030"
}

.si-facebook:before {
  content: "\E031"
}

.si-twitter:before {
  content: "\E032"
}

.si-instagram:before {
  content: "\E033"
}

.si-posterous_spaces:before {
  content: "\E034"
}

.si-vimeo:before {
  content: "\E035"
}

.si-flickr:before {
  content: "\E036"
}

.si-last_fm:before {
  content: "\E037"
}

.si-rss:before {
  content: "\E038"
}

.si-skype:before {
  content: "\E039"
}

.si-e-mail:before {
  content: "\E040"
}

.si-vine:before {
  content: "\E041"
}

.si-myspace:before {
  content: "\E042"
}

.si-goodreads:before {
  content: "\E043"
}

.si-apple:before {
  content: "\F8FF"
}

.si-windows:before {
  content: "\E045"
}

.si-yelp:before {
  content: "\E046"
}

.si-playstation:before {
  content: "\E047"
}

.si-xbox:before {
  content: "\E048"
}

.si-android:before {
  content: "\E049"
}

.si-ios:before {
  content: "\E050"
}

.fi-txt:before {
  content: "\E001"
}

.fi-doc:before {
  content: "\E002"
}

.fi-rtf:before {
  content: "\E003"
}

.fi-log:before {
  content: "\E004"
}

.fi-tex:before {
  content: "\E005"
}

.fi-msg:before {
  content: "\E006"
}

.fi-text:before {
  content: "\E007"
}

.fi-wpd:before {
  content: "\E008"
}

.fi-wps:before {
  content: "\E009"
}

.fi-docx:before {
  content: "\E010"
}

.fi-page:before {
  content: "\E011"
}

.fi-csv:before {
  content: "\E012"
}

.fi-dat:before {
  content: "\E013"
}

.fi-tar:before {
  content: "\E014"
}

.fi-xml:before {
  content: "\E015"
}

.fi-vcf:before {
  content: "\E016"
}

.fi-pps:before {
  content: "\E017"
}

.fi-key:before {
  content: "\E018"
}

.fi-ppt:before {
  content: "\E019"
}

.fi-pptx:before {
  content: "\E020"
}

.fi-sdf:before {
  content: "\E021"
}

.fi-gbr:before {
  content: "\E022"
}

.fi-ged:before {
  content: "\E023"
}

.fi-mp3:before {
  content: "\E024"
}

.fi-m4a:before {
  content: "\E025"
}

.fi-waw:before {
  content: "\E026"
}

.fi-wma:before {
  content: "\E027"
}

.fi-mpa:before {
  content: "\E028"
}

.fi-iff:before {
  content: "\E029"
}

.fi-aif:before {
  content: "\E030"
}

.fi-ra:before {
  content: "\E031"
}

.fi-mid:before {
  content: "\E032"
}

.fi-m3v:before {
  content: "\E033"
}

.fi-e_3gp:before {
  content: "\E034"
}

.fi-shf:before {
  content: "\E035"
}

.fi-avi:before {
  content: "\E036"
}

.fi-asx:before {
  content: "\E037"
}

.fi-mp4:before {
  content: "\E038"
}

.fi-e_3g2:before {
  content: "\E039"
}

.fi-mpg:before {
  content: "\E040"
}

.fi-asf:before {
  content: "\E041"
}

.fi-vob:before {
  content: "\E042"
}

.fi-wmv:before {
  content: "\E043"
}

.fi-mov:before {
  content: "\E044"
}

.fi-srt:before {
  content: "\E045"
}

.fi-m4v:before {
  content: "\E046"
}

.fi-flv:before {
  content: "\E047"
}

.fi-rm:before {
  content: "\E048"
}

.fi-png:before {
  content: "\E049"
}

.fi-psd:before {
  content: "\E050"
}

.fi-psp:before {
  content: "\E051"
}

.fi-jpg:before {
  content: "\E052"
}

.fi-tif:before {
  content: "\E053"
}

.fi-tiff:before {
  content: "\E054"
}

.fi-gif:before {
  content: "\E055"
}

.fi-bmp:before {
  content: "\E056"
}

.fi-tga:before {
  content: "\E057"
}

.fi-thm:before {
  content: "\E058"
}

.fi-yuv:before {
  content: "\E059"
}

.fi-dds:before {
  content: "\E060"
}

.fi-ai:before {
  content: "\E061"
}

.fi-eps:before {
  content: "\E062"
}

.fi-ps:before {
  content: "\E063"
}

.fi-svg:before {
  content: "\E064"
}

.fi-pdf:before {
  content: "\E065"
}

.fi-pct:before {
  content: "\E066"
}

.fi-indd:before {
  content: "\E067"
}

.fi-xlr:before {
  content: "\E068"
}

.fi-xls:before {
  content: "\E069"
}

.fi-xlsx:before {
  content: "\E070"
}

.fi-db:before {
  content: "\E071"
}

.fi-dbf:before {
  content: "\E072"
}

.fi-mdb:before {
  content: "\E073"
}

.fi-pdb:before {
  content: "\E074"
}

.fi-sql:before {
  content: "\E075"
}

.fi-aacd:before {
  content: "\E076"
}

.fi-app:before {
  content: "\E077"
}

.fi-exe:before {
  content: "\E078"
}

.fi-com:before {
  content: "\E079"
}

.fi-bat:before {
  content: "\E080"
}

.fi-apk:before {
  content: "\E081"
}

.fi-jar:before {
  content: "\E082"
}

.fi-hsf:before {
  content: "\E083"
}

.fi-pif:before {
  content: "\E084"
}

.fi-vb:before {
  content: "\E085"
}

.fi-cgi:before {
  content: "\E086"
}

.fi-css:before {
  content: "\E087"
}

.fi-js:before {
  content: "\E088"
}

.fi-php:before {
  content: "\E089"
}

.fi-xhtml:before {
  content: "\E090"
}

.fi-htm:before {
  content: "\E091"
}

.fi-html:before {
  content: "\E092"
}

.fi-asp:before {
  content: "\E093"
}

.fi-cer:before {
  content: "\E094"
}

.fi-jsp:before {
  content: "\E095"
}

.fi-cfm:before {
  content: "\E096"
}

.fi-aspx:before {
  content: "\E097"
}

.fi-rss:before {
  content: "\E098"
}

.fi-csr:before {
  content: "\E099"
}

.fi-less:before {
  content: "\003C"
}

.fi-otf:before {
  content: "\E101"
}

.fi-ttf:before {
  content: "\E102"
}

.fi-font:before {
  content: "\E103"
}

.fi-fnt:before {
  content: "\E104"
}

.fi-eot:before {
  content: "\E105"
}

.fi-woff:before {
  content: "\E106"
}

.fi-zip:before {
  content: "\E107"
}

.fi-zipx:before {
  content: "\E108"
}

.fi-rar:before {
  content: "\E109"
}

.fi-targ:before {
  content: "\E110"
}

.fi-sitx:before {
  content: "\E111"
}

.fi-deb:before {
  content: "\E112"
}

.fi-e_7z:before {
  content: "\E113"
}

.fi-pkg:before {
  content: "\E114"
}

.fi-rpm:before {
  content: "\E115"
}

.fi-cbr:before {
  content: "\E116"
}

.fi-gz:before {
  content: "\E117"
}

.fi-dmg:before {
  content: "\E118"
}

.fi-cue:before {
  content: "\E119"
}

.fi-bin:before {
  content: "\E120"
}

.fi-iso:before {
  content: "\E121"
}

.fi-hdf:before {
  content: "\E122"
}

.fi-vcd:before {
  content: "\E123"
}

.fi-bak:before {
  content: "\E124"
}

.fi-tmp:before {
  content: "\E125"
}

.fi-ics:before {
  content: "\E126"
}

.fi-msi:before {
  content: "\E127"
}

.fi-cfg:before {
  content: "\E128"
}

.fi-ini:before {
  content: "\E129"
}

.fi-prf:before {
  content: "\E130"
}
